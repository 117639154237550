<template lang='pug'>
	.content
</template>

<script>
export default {
	metaInfo: {
		title: 'Safe.is - Driving in Iceland.',
		meta: [
			{
				name: 'og:site_name',
				property: 'og:site_name',
				content: 'Safe.is'
			},
			{
				name: 'og:image',
				property: 'og:image',
				content: 'https://safe.is/interesting/summer.jpg'
			},
			{
				name: 'og:title',
				property: 'og:title',
				content: 'I took a quiz on Safe.is.'
			},
			{
				name: 'og:description',
				property: 'og:description',
				content: 'A bit unsure? When it comes to driving, it is better to be sure that you are ready. We suggest that you retake the test after you have taken a bit closer look to our pages about Safe driving and Driving in Iceland.'
			},
			{
				name: 'description',
				property: 'description',
				content: 'A bit unsure? When it comes to driving, it is better to be sure that you are ready. We suggest that you retake the test after you have taken a bit closer look to our pages about Safe driving and Driving in Iceland.'
			}
		]
	},
	mounted: function () {
		this.$nextTick(() => {
			setTimeout(() => {
				this.$router.push('/');
			}, 300);
		});
	}
};
</script>

<style>

</style>
